/* eslint-disable no-console */
import { register } from 'register-service-worker';
import { getConfigEnv } from '@/utils/helpers';
import eventBus from '@/lib/eventBus';
import { SHOW_ALERT } from '@/lib/constants';

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(
        'App is being served from cache by a service worker.\n'
        + 'For more details, visit https://goo.gl/AFskqB',
      );
    },
    registered() {
      console.log('Service worker has been registered.');
    },
    cached() {
      console.log('Content has been cached for offline use.');
    },
    updatefound() {
      eventBus.$emit(SHOW_ALERT, 'Update found. Downloading update');
      console.log('New content is downloading.');
    },
    async updated(registration) {
      console.log('New content is available; please refresh.');
      const worker = registration.waiting;
      if (getConfigEnv('FORCE_UPDATE')) {
        console.info('Applying system update...');
        await worker?.postMessage({ action: 'SKIP_WAITING' });
        setTimeout(() => {
          eventBus.$emit(SHOW_ALERT, 'The app was auto updated!');
          console.info('Reloading the app...');
          window.location.reload(true);
        }, 1500);
        return;
      }
    },
    offline() {
      console.log('No internet connection found. App is running in offline mode.');
    },
    error(error) {
      console.error('Error during service worker registration:', error);
    },
  });
}
