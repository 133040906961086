import { getView } from '@/utils/helpers';

export default {
  path: '/kanban',
  name: 'TasqsKanban',
  meta: {
    parent: 'Tasqs',
  },
  component: () => getView('Kanban'),
};
