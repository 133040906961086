export default {
  OPERATOR_LOWERCASED: 'pdc',
  OPERATOR_PASCALED: 'PDC',
  ENV_PREFIX: 'prod',
  AUTH_ENDPOINT_URL: 'https://rjwqztkxc8.execute-api.us-east-1.amazonaws.com/pdc',
  PREDICTIONS_ENDPOINT_URL: 'https://5lpvyoxzhnhxpkhzcmdma2r5vy.appsync-api.us-east-2.amazonaws.com/graphql',
  CLEAN_DATA_ENDPOINT_URL: 'https://zyx5hai2prenfigkvamwgdmttu.appsync-api.us-east-2.amazonaws.com/graphql',
  USER_RESPONSES_ENDPOINT_URL: 'https://dbzykpjtonguzdod2dn5o5vrqy.appsync-api.us-east-2.amazonaws.com/graphql',
  MAP_LAYERS_ENDPOINT_URL: 'https://snpjos44erdjzlylg2d5lbsxrm.appsync-api.us-east-2.amazonaws.com/graphql',
  ANALYTICS_ENDPOINT_URL: 'https://4irgndbnm5hm7amkr77drubuy4.appsync-api.us-east-2.amazonaws.com/graphql',
  DEFERMENT_ENDPOINT_URL: 'https://jrr3hv7vmvhmdgebfazyievnj4.appsync-api.us-east-2.amazonaws.com/graphql',
  WORKFLOW_ENDPOINT_URL: 'https://c6dv7qeca5gnhmf2xmwcu3jjtm.appsync-api.us-east-2.amazonaws.com/graphql',
  NOTIFICATION_ENDPOINT_URL: 'https://nnw7ddwn4vawjpipomqe22fyse.appsync-api.us-east-2.amazonaws.com/graphql',
  SCHEDULE_ENDPOINT_URL: 'https://mvh4zqltvjd7racures3mdawky.appsync-api.us-east-2.amazonaws.com/graphql',
  PROCEDURES_ENDPOINT_URL: 'https://w64cb3vlvzbtli6lt232drmoaa.appsync-api.us-east-2.amazonaws.com/graphql',
  SETPOINT_ENDPOINT_URL: 'https://7rau5nacjjcfbkur5mhgy26rca.appsync-api.us-east-2.amazonaws.com/graphql',
  REPORT_ENDPOINT_URL: 'https://mu2epcqlk5auzf6xjb4uy2slmi.appsync-api.us-east-2.amazonaws.com/graphql',
  APPSYNC_REGION: 'us-east-2',
  SIGNALS_ARRAY: [
    {
      name: 'Flowrate',
      slug: 'Flowrate',
    },
    {
      name: 'Min Flowrate',
      slug: 'Min Flowrate',
    },
    {
      name: 'Fluid Load',
      slug: 'Fluid Load',
    },
    {
      name: 'Casing Pressure',
      slug: 'Casing Pressure',
    },
    {
      name: 'Tubing Pressure',
      slug: 'Tubing Pressure',
    },
    {
      name: 'Current Position',
      slug: 'Current Position',
    },
    {
      name: 'Oil Static Pressure',
      slug: 'Oil Static Pressure',
    },
    {
      name: 'Arrival Timer Setpoint',
      slug: 'Arrival Timer Setpoint',
    },
    {
      name: 'Static Pressure',
      slug: 'Static Pressure',
    },
    {
      name: 'INJ Flowrate',
      slug: 'INJ Flowrate',
    },
    {
      name: 'Well State',
      slug: 'Well State',
    },
    {
      name: 'Max Flowrate',
      slug: 'Max Flowrate',
    },
    {
      name: 'Plunger Afterflow Mode',
      slug: 'Plunger Afterflow Mode',
    },
    {
      name: 'Plunger > Last Run Time',
      slug: 'Plunger > Last Run Time',
    },
    {
      name: 'Tubing - Line Setpoint',
      slug: 'Tubing - Line Setpoint',
    },
    {
      name: 'time',
      slug: 'time',
    },
    {
      name: 'Valve Status',
      slug: 'Valve Status',
    },
    {
      name: 'DeviceID',
      slug: 'DeviceID',
    },
    {
      name: 'BB Flowrate',
      slug: 'BB Flowrate',
    },
    {
      name: 'INJ Static Pressure',
      slug: 'INJ Static Pressure',
    },
    {
      name: 'VRU Flowrate',
      slug: 'VRU Flowrate',
    },
    {
      name: 'Current RPM',
      slug: 'Current RPM',
    },
    {
      name: 'Tubing Pressure Setpoint',
      slug: 'Tubing Pressure Setpoint',
    },
    {
      name: 'Water Static Pressure',
      slug: 'Water Static Pressure',
    },
    {
      name: 'Oil Flowrate',
      slug: 'Oil Flowrate',
    },
    {
      name: 'CDP Static Pressure',
      slug: 'CDP Static Pressure',
    },
    {
      name: 'Plunger > Last Afterflow Time',
      slug: 'Plunger > Last Afterflow Time',
    },
    {
      name: 'Plunger Last Run Time',
      slug: 'Plunger Last Run Time',
    },
    {
      name: 'Plunger Last Run Speed',
      slug: 'Plunger Last Run Speed',
    },
    {
      name: 'Today Average SPM',
      slug: 'Today Average SPM',
    },
    {
      name: 'SPM',
      slug: 'SPM',
    },
    {
      name: 'Pump Fillage',
      slug: 'Pump Fillage',
    },
    {
      name: 'Pump Fillage Control Setpoint',
      slug: 'Pump Fillage Control Setpoint',
    },
    {
      name: 'Current Load',
      slug: 'Current Load',
    },
    {
      name: 'Plunger Fall Delay Mode',
      slug: 'Plunger Fall Delay Mode',
    },
    {
      name: 'VRU Static Pressure',
      slug: 'VRU Static Pressure',
    },
    {
      name: 'Plunger Today Runs',
      slug: 'Plunger Today Runs',
    },
    {
      name: 'Shut Well Button',
      slug: 'Shut Well Button',
    },
    {
      name: 'Today Run Time',
      slug: 'Today Run Time',
    },
    {
      name: 'Plunger Well Status',
      slug: 'Plunger Well Status',
    },
    {
      name: 'Today Cycle Count',
      slug: 'Today Cycle Count',
    },
    {
      name: 'CDP Flowrate',
      slug: 'CDP Flowrate',
    },
    {
      name: 'BB Static Pressure',
      slug: 'BB Static Pressure',
    },
    {
      name: 'Plunger > # Fails Today',
      slug: 'Plunger > # Fails Today',
    },
    {
      name: 'Plunger Failure Setpoint',
      slug: 'Plunger Failure Setpoint',
    },
    {
      name: 'Plunger Yesterday Runs',
      slug: 'Plunger Yesterday Runs',
    },
    {
      name: 'Today Total Strokes',
      slug: 'Today Total Strokes',
    },
    {
      name: 'Flowrate Setpoint',
      slug: 'Flowrate Setpoint',
    },
    {
      name: 'Water Flowrate',
      slug: 'Water Flowrate',
    },
    {
      name: 'Plunger Failures',
      slug: 'Plunger Failures',
    },
    {
      name: 'Time in State',
      slug: 'Time in State',
    },
    {
      name: 'Plunger > # Runs Today',
      slug: 'Plunger > # Runs Today',
    },
    {
      name: 'Low Flow Timer Setpoint',
      slug: 'Low Flow Timer Setpoint',
    },
    // {
    //   "name": "Surface Card",
    //   "slug": "Surface Card"
    // },
    {
      name: 'Downhole Stroke Length',
      slug: 'Downhole Stroke Length',
    },
    {
      name: 'Surface Position',
      slug: 'Surface Position',
    },
    {
      name: 'Min Load',
      slug: 'Min Load',
    },
    {
      name: 'Downhole Min Load',
      slug: 'Downhole Min Load',
    },
    {
      name: 'Surface Polished Rod HP',
      slug: 'Surface Polished Rod HP',
    },
    {
      name: 'Downhole Pump Fillage',
      slug: 'Downhole Pump Fillage',
    },
    {
      name: 'Downhole Position',
      slug: 'Downhole Position',
    },
    {
      name: 'Surface Load',
      slug: 'Surface Load',
    },
    {
      name: 'surface_position_ideal_real_correlation',
      slug: 'surface_position_ideal_real_correlation',
    },
    {
      name: 'Surface Max Load',
      slug: 'Surface Max Load',
    },
    {
      name: 'Downhole Classifications',
      slug: 'Downhole Classifications',
    },
    {
      name: 'Max Load',
      slug: 'Max Load',
    },
    // {
    //   "name": "Downhole Card",
    //   "slug": "Downhole Card"
    // },
    {
      name: 'Surface Min Load',
      slug: 'Surface Min Load',
    },
    {
      name: 'Surface Stroke Length',
      slug: 'Surface Stroke Length',
    },
    {
      name: 'Downhole Load',
      slug: 'Downhole Load',
    },
    {
      name: 'Downhole Max Load',
      slug: 'Downhole Max Load',
    },
  ],
  FEEDBACK_REASONS: [
    'Line Pressure',
    'Surface restriction failure',
    'Dump failure',
    'Power failure',
    'Comms failure',
    'Control valve malfunction',
    'Plunger failure',
    'Wellhead failure',
    'Non arrival',
    'RTU Board failure',
    'Transmitter failure',
    'Pump failure',
    'Planned activity',
    'Well loaded',
    'Well unloading',
    'Tubing transducer freeze',
    'Compressor issue',
    'Unknown',
  ],
  PROCEDURE_FAILURES: [
    'Line Pressure',
    'Surface restriction failure',
    'Dump failure',
    'Power failure',
    'Comms failure',
    'Control valve malfunction',
    'Plunger failure',
    'Wellhead failure',
    'Non arrival',
    'RTU Board failure',
    'Transmitter failure',
    'Pump failure',
    'Tubing transducer freeze',
    'Compressor issue',
  ],
  WORKFLOW_SIGNALS_ARRAY: [
    {
      name: 'Plunger Yesterday Runs',
    },
    {
      name: 'SPM',
    },
    {
      name: 'Current RPM',
    },
    {
      name: 'Time',
    },
    {
      name: 'Current position',
    },
    {
      name: 'Today cycle count',
    },
    {
      name: 'Today total strokes',
    },
    {
      name: 'Fluid load',
    },
    {
      name: 'Fluid load',
    },
    {
      name: 'Plunger Today Runs',
    },
    {
      name: 'Injection flowrate',
    },
    {
      name: 'Plunger failures',
    },
  ],
  MOBILE_SITE: 'https://mobile.pdc.tasqinc.com',
  FORCE_UPDATE: true,
};
