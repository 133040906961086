import VueTailwind from 'vue-tailwind';
import { VueConstructor } from 'vue';
import {
  TDatepicker,
  TToggle,
  TInput,
  TTextarea,
  TRadio,
  TRadioGroup,
  TCheckbox,
  TCheckboxGroup,
  TSelect,
  TDialog,
  TRichSelect,
  TAlert,
} from 'vue-tailwind/dist/components';

const components = {
  TDatepicker,
  TToggle,
  TInput,
  TTextarea,
  TRadio,
  TRadioGroup: {
    component: TRadioGroup,
    props: {
      classes: {
        wrapper: 'grid grid-flow-col mb-1 justify-start',
        input: 'mr-2 bordered-radio',
      },
      variants: {
        error: {
          input: 'text-red-500 transition duration-100 ease-in-out',
          label: 'bg-red-100 text-red-500',
        },
      },
    },
  },
  TCheckbox,
  TCheckboxGroup: {
    component: TCheckboxGroup,
    props: {
      classes: {
        wrapper: 'grid grid-flow-col justify-start mb-2',
        input: 'mr-2 bordered-radio rounded-full',
      },
      variants: {
        error: {
          input: 'text-red-500 transition duration-100 ease-in-out',
          label: 'bg-red-100 text-red-500',
        },
      },
    },
  },
  TSelect,
  TDialog,
  TRichSelect,
  TAlert: {
    component: TAlert,
    props: {
      fixedClasses: {
        wrapper: 'fixed flex items-center p-4 border-l-4  rounded shadow-sm',
        body: 'flex-grow',
        close: 'absolute relative flex items-center justify-center ml-4 flex-shrink-0 w-6 h-6 transition duration-100 ease-in-out rounded  focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50',
        closeIcon: 'fill-current h-4 w-4'
      },
      classes: {
        wrapper: 'bg-white border-cyan',
        body: 'text-cyan',
        close: 'text-charcoal'
      },
    }
  }
};

export default function installVueTailwind(Vue: VueConstructor) {
  Vue.use(VueTailwind, components);
}
