// TODO REFACTOR WITH TS SUPPORT

/* eslint-disable no-console */
import jwtDecode from 'jwt-decode';
import jwt from 'jsonwebtoken';
import jwkToPem from 'jwk-to-pem';
import axios from 'axios';
import { getConfigEnv } from '@/utils/helpers';

/**
 * Returns token expiration date from JWT header
 *
 * @link https://jwt.io/
 * @param token
 * @returns {*}
 */
function decodeToken(token) {
  try {
    return jwtDecode(token);
  } catch (e) {
    localStorage.removeItem('AccessToken');
    localStorage.removeItem('RefreshToken');
    window.location = '/login';
    return {};
  }
}

function getTokenExpirationDate(encodedToken) {
  if (!encodedToken) {
    return null;
  }
  const token = decodeToken(encodedToken);

  if (!token.exp) return null;

  return new Date(token.exp * 1000);
}

/**
 * Retrieves JSON Web Key based on Token issuer and key id
 *
 * @param issuer
 * @param keyId
 * @returns {*}
 */
function getJWK(issuer, keyId) {
  return fetch(`${issuer}/.well-known/jwks.json`)
    .then((res) => res.json())
    .then(({ keys }) => keys.find((key) => key.kid === keyId));
}

/**
 * Retrieves a token from a storage
 *
 * @returns {string | null | null}
 */
function getAccessToken() {
  return localStorage.getItem('AccessToken') || null;
}

/**
 * Stores a token to a storage
 *
 * @param token
 */
function setAccessToken(token) {
  localStorage.setItem('AccessToken', token);
  return token;
}

/**
 * Returns true if token is expired
 *
 * @param token
 * @returns {boolean}
 */
function isTokenExpired(token = getAccessToken()) {
  if (!token) {
    return true;
  }

  const now = new Date();
  const expirationDate = getTokenExpirationDate(token);

  return expirationDate.getTime() <= now.getTime();
}

/**
 * Returns true if token is broken and not conforms to RFC/7519
 *
 * @link https://tools.ietf.org/html/rfc7519
 * @link https://jwt.io/
 * @param token
 * @returns {boolean}
 */
async function isTokenMalformed(token = getAccessToken()) {
  if (!token) {
    return true;
  }
  const segments = token.split('.');
  let res = false;
  try {
    const header = JSON.parse(atob(segments[0]));
    const payload = JSON.parse(atob(segments[1]));
    const jwk = await getJWK(payload.iss, header.kid);
    const pem = jwkToPem(jwk);
    jwt.verify(token, pem);
  } catch (e) {
    res = true;
    console.warn('Token is malformed');
  }
  return res;
}

/**
 * Retrieves and stores a new AccessToken
 *
 * @returns {PromiseLike<{data: *}> | Promise<{data: *}> | *}
 */
const refreshAccessToken = async () => {
  const { email } = decodeToken(getAccessToken());
  const refreshToken = localStorage.getItem('RefreshToken');

  try {
    const { data: { tokens: { IdToken: AccessToken } } } = await axios.post(
      `${getConfigEnv('AUTH_ENDPOINT_URL')}/auth`,
      {
        type: 'refresh',
        email,
        refreshToken,
      },
    );
    return setAccessToken(AccessToken);
  } catch (e) {
    throw new Error(e);
  }
};

/**
 * Clears tokens
 */
function clearSession() {
  localStorage.removeItem('AccessToken');
  localStorage.removeItem('RefreshToken');
}

/**
 * Returns true, if user's token is not expired
 *
 * @returns {boolean}
 */
function isLoggedIn() {
  const AccessToken = getAccessToken();
  const isExpired = isTokenExpired(AccessToken);

  return AccessToken && !isExpired;
}

/**
 * Redirects non-authenticated users to the home page
 *
 * @param to
 * @param from
 * @param next
 * @returns {*}
 */
async function requireAuth(to, from, next) {
  if (!isLoggedIn()) {
    try {
      await refreshAccessToken();
      next();
    } catch (e) {
      clearSession();
      return next({ name: 'Login' });
    }
  }
  return next();
}

/**
 * Redirects authenticated users to the home page
 *
 * @param to
 * @param from
 * @param next
 * @returns {*}
 */
function rejectAuth(to, from, next) {
  if (isLoggedIn()) {
    return next({ path: '/' });
  }

  return next();
}

/**
 * Indicates the current state of access token expiration.
 * If token not yet expired true should be returned
 */
async function isTokenValid() {
  const token = getAccessToken();
  const isMalformed = await isTokenMalformed(token);
  const isExpired = isTokenExpired(token);

  return token && !isMalformed && !isExpired;
}

export {
  getTokenExpirationDate,
  isTokenExpired,
  isLoggedIn,
  refreshAccessToken,
  getAccessToken,
  setAccessToken,
  rejectAuth,
  requireAuth,
  isTokenValid,
  decodeToken,
  clearSession,
};
