import store from '@/store';
import {
  VuexModule,
  Module,
  getModule,
  Action,
  Mutation,
} from 'vuex-module-decorators';
import { getConfigEnv, jsonParse } from '@/utils/helpers';
import { decodeToken } from '@/lib/auth';
import workflowApolloClient from '@/lib/appsync/workflow';
import axios from 'axios';
import getUserReassignmentList from '@/graphql/workflow/queries/getUserReassignmentList.graphql';

@Module({
  dynamic: true,
  namespaced: true,
  name: 'accountModule',
  store,
})
class AccountModule extends VuexModule {
  reassignmentList: ReassignUser[] = [];

  user: User = {} as User;




  @Mutation
  updateFirstName(newFirstName): void {
    this.user = newFirstName
  }





  @Mutation
  setUser(): void {
    const token = localStorage.getItem('AccessToken');
    if (token) {
      this.user = {
        id: decodeToken(token).sub,
        username: decodeToken(token)['cognito:username'],
        email: decodeToken(token).email,
        groups: decodeToken(token)['cognito:groups'],
        isAdmin: (decodeToken(token)['cognito:groups'] || []).includes('AdminGroup'),
        phoneNumber: decodeToken(token)["phone_number"],
        firstName: decodeToken(token)["custom:first_name"],
        lastName: decodeToken(token)["custom:last_name"],
      };
      // @ts-ignore
      window.Appcues.identify(decodeToken(token).email);
    }
  }

  @Mutation
  setReassignmentList(list): void {
    this.reassignmentList = list;
  }

  @Action
  async login({ email }): Promise<void> {
    const { data: { session, tokens } } = await axios.post(`${getConfigEnv('AUTH_ENDPOINT_URL')}/auth`, {
      type: 'login',
      email,
    });
    if (session) {
      localStorage.setItem('session', session);
      localStorage.setItem('email', email);
    }
  }

  @Action
  async verify({ code }): Promise<void> {
    const { data: { tokens } } = await axios.post(`${getConfigEnv('AUTH_ENDPOINT_URL')}/auth`, {
      type: 'respond',
      session: localStorage.getItem('session'),
      email: localStorage.getItem('email'),
      code,
    });
    if (tokens) {
      localStorage.removeItem('IdToken');
      localStorage.removeItem('RefreshToken');
      Object.keys(tokens).map((token) => localStorage.setItem(token === 'IdToken' ? 'AccessToken' : token, tokens[token]));
    }
  }

  @Action({
    commit: 'setReassignmentList',
  })
  async getReassignmentList(): Promise<ReassignUser[]> {
    try {
      const {
        data:
          {
            get_user_reassignment_list: {
              UserDict: userDict,
            },
          },
      } = await workflowApolloClient.query({
        query: getUserReassignmentList,
        variables: {
		  Timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          Operator: getConfigEnv('OPERATOR_LOWERCASED'),
        },
      });

      const items = jsonParse(userDict) || {};
      return Object.values(items)
        .map((u, idx) => ({
          // @ts-ignore
          ...u,
          email: Object.keys(items)[idx],
          name: `${u.name_dict?.first_name || Object.keys(items)[idx]} ${u.name_dict?.last_name || ''}`,
          totalAssignments: u.TotalCount,
        }));
    } catch (e) {
		console.log("CONFUSED")
		console.log(e)
      throw new Error(e);
    }
  }
}

export default getModule(AccountModule);
