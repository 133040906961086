import eventBus from '@/lib/eventBus';
import {
  getEnv,
} from '@/utils/helpers';
import VueTouch from 'vue-touch';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';
import VueCompositionAPI from '@vue/composition-api';
import clickOutside from '@/utils/click-outside';
import * as constants from '@/lib/constants';

// Service worker
import '@/lib/registerServiceWorker';
import { VueConstructor } from 'vue';

function loadPlugins(Vue: VueConstructor) {
  Vue.use(VueTouch);
  // Bugsnag
  const stage = getEnv('ENV') === 'local' ? getEnv('ENV') : getEnv('BUGSNAG_RELEASE_STAGE');
  Bugsnag.start({
    apiKey: '5d6a3297ecf856b847c38f1d2658b5fb',
    appVersion: '1.0.0',
    plugins: [
      new BugsnagPluginVue(),
    ],
    enabledReleaseStages: [`${getEnv('BUGSNAG_BASE_STAGE')}-dev`, getEnv('BUGSNAG_BASE_STAGE') || ''],
    releaseStage: stage,
    onError: (event) => {
      const { message } = event.originalError;

      const token = localStorage.getItem('IdToken');
      if (token) {
        event.setUser(
          // decodeToken(token).sub,
          // decodeToken(token).email,
          // ucwords(decodeToken(token)['cognito:username']),
        );
      }

      console.warn(event.originalError); // eslint-disable-line no-console

      if (
        message.toLowerCase().includes('status code 400')
        || message.toLowerCase().includes('esmodule')
        || message.toLowerCase().includes('can\'t find field')
        || message.toLowerCase().includes('unexpected token')
        || message.toLowerCase().includes('status code 401')
      ) {
        return false;
      }

      return true;
    },
  });
  // eslint-disable-next-line no-unused-expressions
  Bugsnag.getPlugin('vue')?.installVueErrorHandler(Vue);

  // Composition API
  Vue.use(VueCompositionAPI);
}

function loadGlobals(Vue: VueConstructor) {
  Object.assign(Vue.prototype, {
    $eventBus: eventBus,
    $getConst: (key) => constants[key] || key,
  });
}

function loadDirectives(Vue) {
  Vue.directive('custom-click-outside', clickOutside);
}

export default function installVue(Vue: VueConstructor) {
  loadPlugins(Vue);
  loadGlobals(Vue);
  loadDirectives(Vue);
}
