








import { Component, Vue } from 'vue-property-decorator';
import accountModule from '@/store/modules/accountModule';
import {
  getAccessToken,
  isTokenExpired,
  refreshAccessToken,
} from '@/lib/auth';
import { isMobile } from 'mobile-device-detect';
import { getConfigEnv, isProd } from '@/utils/helpers';
import Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

@Component({
  components: {},
})
export default class App extends Vue {
  beforeCreate() {
    if (isMobile && !isProd()) {
      window.location.href = getConfigEnv('MOBILE_SITE')
    }
  }

  async created() {
    if (!this.$route.meta.skipAuth && getAccessToken()) {
      if (getAccessToken() && isTokenExpired()) {
        await refreshAccessToken();
      }
      await accountModule.getReassignmentList();
    }
  }

  mounted() {
    Chart.plugins.unregister(ChartDataLabels);
  }
}
